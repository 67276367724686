import React from "react";
import Link from "@mui/material/Link";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
// Change?
const MobileTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const Biography = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const currentYear = new Date().getFullYear();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        sx={{ fontFamily: "system-ui, sans-serif" }}
        variant="h3"
        component="div"
      >
        Berkay Gemici
      </Typography>
      <Card
        sx={{
          marginTop: 1,
          display: "flex",
          width: "100%",
          padding: 1,
          "&:hover": {
            boxShadow:
              "none" /* transform: 'scale(1.05)', transition: 'transform 0.2s ease-in-out'*/,
          },
        }}
      >
        {!isMobile && (
          <CardMedia
            sx={{ width: 200, height: 200, borderRadius: 1 }}
            component="img"
            image={require("../images/cards/berkay.jpg")}
            style={{ pointerEvents: "none" }}
          />
        )}
        {!isMobile && (
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "200px",
              overflow: "auto",
            }}
          >
            <Typography variant="h5" component="div">
              Hi, I'm Berkay 👋🏻
            </Typography>
            <Typography variant="body1">
              I'm a full-stack developer and I love building
              <br />
              AI-integrated projects & playing with real-world data.
            </Typography>
            <Typography
              onClick={handleClickOpen}
              sx={{ "&:hover": { cursor: "pointer" }, color: "#34a1eb" }}
            >
              Read more.
            </Typography>
          </CardContent>
        )}

        {isMobile && (
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "auto",
              overflow: "auto",
            }}
          >
            <Typography variant="h5" component="div">
              Hi, I'm Berkay 👋🏻
            </Typography>
            <Typography variant="body1">
              I'm a full-stack developer and I love building
              <br />
              AI-integrated projects & playing with real-world data.
            </Typography>
            <Typography
              onClick={handleClickOpen}
              sx={{ "&:hover": { cursor: "pointer" }, color: "#34a1eb" }}
            >
              Read more.
            </Typography>
          </CardContent>
        )}
      </Card>

      {isMobile && (
        <Dialog
          open={open}
          TransitionComponent={MobileTransition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            About
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "none",
                transform: "scale(1.1)",
                transition: "transform 0.2s ease-in-out",
              },
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-slide-description">
              I am a {currentYear - 2002}-year-old business informatics student
              at TU Braunschweig, who is deeply enamored with the world of
              informatics.
              <br />
              <br />
              I was born in a informatics world, and since I first touched a
              computer I am in love with these things.
              <br />
              <br />
              In addition to my academic pursuits, I consistently strive for
              personal development. I concurrently engage in honing my skills as
              a full-stack developer, while also dedicating time to study
              machine learning in pursuit of my aspiration to become a data
              scientist.
              <br />
              <br />
              I'm trained in cloud computing and most of the time building on
              cloud infrastructure. Since I've spent more than 3 years as a
              freelancer, I've seen many development scenarios and workflows.
              <br />
              <br />
              Thanks to those experiences, I learned to identify problems,
              design solutions and apply them by broadly thinking when
              developing a software/application architecture.
              <br />
              <br />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
      {!isMobile && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            About
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "none",
                transform: "scale(1.1)",
                transition: "transform 0.2s ease-in-out",
              },
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-slide-description">
              I am a {currentYear - 2002}-year-old business informatics student
              at TU Braunschweig, who is deeply enamored with the world of
              informatics.
              <br />
              <br />
              I was born into the world of technology, and ever since my initial
              encounter with a computer, I have cherished a deep passion for
              these devices.
              <br />
              <br />
              While I do full stack developing, I study and learn Machine
              Learning for my dream data scientist position.
              <br />
              <br />
              I'm trained in cloud computing and most of the time building on
              cloud infrastructure. Since I've spent more than 3 years as a
              freelancer, I've seen many development scenarios and workflows.
              <br />
              <br />
              Thanks to those experiences, I learned to identify problems,
              design solutions and apply them by broadly thinking when
              developing a software/application architecture.
              <br />
              <br />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default Biography;
