import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Grow from "@mui/material/Grow";
import { Octokit } from "@octokit/core";
import { Link } from "@mui/material";

// Change?
const Showcase = () => {
  const [repos, setRepos] = useState([]);

  const itemsPerPage = 2;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedItems = repos.slice(startIndex, endIndex);

  const totalPages = Math.ceil(repos.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const fetchData = async () => {
      const octokit = new Octokit({
        auth: "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",
      });

      try {
        const response = await octokit.request("GET /user/repos", {
          headers: {
            "X-GitHub-Api-Version": "2022-11-28",
          },
        });

        const repositories = response.data;
        setRepos(repositories);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Typography
        variant="h4"
        component="div"
        sx={{
          marginTop: 0,
          animation: "changeColor 5s infinite",
          "@keyframes changeColor": {
            "0%": { color: "#8e44ad" },
            "25%": { color: "#3498db" },
            "50%": { color: "#2980b9" },
            "75%": { color: "#1abc9c" },
            "100%": { color: "#8e44ad" },
          },
        }}
      >
        My latest releases 👨🏻‍💻
      </Typography>

      <ImageList
        sx={{
          width: "100%",
          minHeight: "200px",
          marginTop: "25px",
          margin: "10px auto 0",
          overflow: "hidden",
        }}
      >
        {paginatedItems.map((item, index) => (
          <Link
            sx={{
              "&:hover": {
                opacity: 0.5,
                color: "red",
                transition: "opacity 0.3s ease-in-out",
              },
              "&:not(:hover)": {
                opacity: 1,
                transition: "opacity 0.2s ease-in-out",
              },
            }}
            href={item.html_url}
          >
            <Grow in={true} timeout={(index + 1) * 500} key={item.name}>
              <ImageListItem key={item.html_url}>
                <img
                  style={{ pointerEvents: "none" }}
                  srcSet={`${require("../images/cards/repo.png")}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  src={`${require("../images/cards/repo.png")}?w=248&fit=crop&auto=format`}
                  alt={item.name}
                  loading="lazy"
                />
                <ImageListItemBar
                  sx={{ width: "100%", height: "20%" }}
                  title={item.name}
                  subtitle={item.description}
                />
              </ImageListItem>
            </Grow>
          </Link>
        ))}
      </ImageList>

      <div style={{ textAlign: "center", marginTop: "20px" }}>
        {currentPage > 1 && (
          <span
            style={{
              cursor: "pointer",
              margin: "0 5px",
              textDecoration: "none",
            }}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </span>
        )}

        {Array.from({ length: totalPages }, (_, index) => (
          <span
            key={index + 1}
            style={{
              cursor: "pointer",
              margin: "0 5px",
              textDecoration: currentPage === index + 1 ? "underline" : "none",
            }}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </span>
        ))}

        {currentPage < Math.ceil(repos.length / itemsPerPage) && (
          <span
            style={{
              cursor: "pointer",
              margin: "0 5px",
              textDecoration: "none",
            }}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === Math.ceil(repos.length / itemsPerPage)}
          >
            Next
          </span>
        )}
      </div>
    </div>
  );
};

export default Showcase;
