import React from "react";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
// Social Assets
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const SocialLinks = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Link
        href="https://github.com/berkaygemici"
        rel="noopener"
        underline="none"
      >
        <IconButton
          size="large"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
              transform: "scale(1.1)",
              transition: "transform 0.2s ease-in-out",
            },
          }}
        >
          <GitHubIcon fontSize="inherit" />
        </IconButton>
      </Link>
      <Link
        href="https://twitter.com/berkayygemicii"
        rel="noopener"
        underline="none"
      >
        <IconButton
          size="large"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
              transform: "scale(1.1)",
              transition: "transform 0.2s ease-in-out",
            },
          }}
        >
          <TwitterIcon fontSize="inherit" />
        </IconButton>
      </Link>
      <Link
        href="https://www.linkedin.com/in/berkay-gemici/"
        rel="noopener"
        underline="none"
      >
        <IconButton
          size="large"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
              transform: "scale(1.1)",
              transition: "transform 0.2s ease-in-out",
            },
          }}
        >
          <LinkedInIcon fontSize="inherit" />
        </IconButton>
      </Link>
    </div>
  );
};

export default SocialLinks;
